<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pt-4>
      <v-flex>
        <v-tabs  fixed-tabs v-model="tabValue" color="#68d389" centered slider-color="yellow">
          <v-tab
            v-for="item in titleArray"
            @click="getPrograms(item.value)"
            :key="item.value"
          >
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </v-flex>
    </v-layout>
    <v-tabs-items v-model="tabValue">
      <template v-for="(item, index) in titleArray">
        <v-tab-item :key="item.value" :value="index">
          <v-layout wrap justify-center pt-4>
            <v-flex xs1 hidden-md-and-down></v-flex>
            <v-flex
              pa-2
              v-for="(cat, i) in categories"
              :key="i"
              text-center
              align-self-center
            >
              <v-btn
                block
                text
                :ripple="false"
                @click="getPrograms(item.value, cat._id)"
              >
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 12px;
                    color: #343434;
                    text-transform: none;
                  "
                >
                  {{ cat.name }}
                </span>
              </v-btn>
            </v-flex>
            <v-flex xs1 hidden-md-and-down></v-flex>
          </v-layout>
          <Programs :programs="programs" @stepper="winStepper"></Programs>
        </v-tab-item>
      </template>
    </v-tabs-items>
  </div>
</template>
<script>
import axios from "axios";
import Programs from "./programs2";
export default {
  components: {
    Programs,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      categories: [],
      programs: [],
      tabValue: 0,
      titleArray: [
        { title: "STARTED", value: "started" },
        { title: "STOPPED", value: "stopped" },
        { title: "PENDING", value: "pending" },
        // { title: "COTTAGES", value: "cottage" },
      ],
    };
  },
  beforeMount() {
    this.getPrograms("started");
    this.getCategories();
  },
  methods: {
    // winStepper(window_data) {
    //   if (window_data.response) this.slotAddition = window_data.slotAddition;
    //   this.packageRate = window_data.response;
    // },
    winStepper(windowData) {
      if (windowData.response.status) {
        this.msg = "Delete Sucessfully";
        this.showSnackbar = true;
        this.getPrograms("started");
        //   this.data=windowData.name
        // console.log(windowData);
      }
      //  else this.date = windowData.date;
    },
    getCategories() {
      axios({
        method: "GET",
        url: "/category/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.categories = response.data.data;
            for (var i = 0; i < this.categories.length; i++) {
              if (this.categories[i].name == "Accomodations") {
                if (!this.isCottage) this.categories.splice(i, 1);
              }
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getPrograms(val, catid) {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/programme/getlist",
        params: { programmestatus: val, category: catid, isCottage: false },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.programs = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/programme/remove",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>